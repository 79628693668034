<template>
  <div>
    <h3>Add User</h3>
    <div class="row">
      <div class="col-md-7">
        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" required v-model="user.name" placeholder="Name">
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" required v-model="user.email" placeholder="Email">
              </div>
              <div class="form-group">
                <label>Role</label>
                <select class="form-control" required v-model="user.role">
                  <option value="admin">Admin</option>
                  <option value="cutomer">Customer</option>
                </select>
              </div>
              <div class="form-group text-end">
                <button class="btn btn-secondary"><i class="lni lni-save me-2"></i>Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {}
    }
  },

  methods: {
    submit() {
      this.axios.post('/api/v1/admin/users', this.user).then(response => {
        this.$router.push({ name: 'admin.users.show', params: { id: response.data.id }})
      })
    }
  }
}
</script>